<template>
      <main>
      <div class="vip-type-list" style="font-size:18px">
        <el-tabs v-model="defaultName" @tab-click="handleClick">
          <el-tab-pane label="资金统计" name="Fund"></el-tab-pane>
          <el-tab-pane label="收入分析" name="Income"></el-tab-pane>
          <el-tab-pane label="库存统计" name="Inventory"></el-tab-pane>
          <el-tab-pane label="账单流水" name="Bill"></el-tab-pane>
          <el-tab-pane label="翻台统计" name="Rockover"></el-tab-pane>
          <el-tab-pane label="存茶统计" name="SaveTea"></el-tab-pane>
          <el-tab-pane label="员工提成" name="StaffAdd"></el-tab-pane>
        </el-tabs>
      </div>
          <component :is="Com" />
    </main>
</template>
<script>
import Fund from '@c/aloneShop/fund.vue'
import Income from '@c/aloneShop/income.vue'
import Inventory from '@c/aloneShop/inventory.vue'
import Bill from '@c/aloneShop/bill.vue'
import Rockover from '@c/aloneShop/rockover.vue'
import SaveTea from '@c/aloneShop/saveTea.vue'
import StaffAdd from '@c/aloneShop/staffAdd.vue'
import { shallowRef ,ref, } from 'vue'
export default {
  computeds:{
    Fund,Income,Inventory,Bill,Rockover,SaveTea,StaffAdd,
  },
  setup() {
    let Com = shallowRef(Fund)
    let defaultName = ref('Fund')
    const handleClick = (tab)=>{
      switch(tab.props.name){
        case 'Fund':
          Com.value = Fund
          break;
        case 'Income':
          Com.value = Income
          break;
        case 'Inventory':
          Com.value = Inventory
          break;
        case 'Bill':
          Com.value = Bill
          break;
        case 'Rockover':
          Com.value = Rockover
          break;
        case 'SaveTea':
          Com.value = SaveTea
          break;
        case 'StaffAdd':
          Com.value = StaffAdd
          break;
        default :
          Com.value = Fund
          break
      }
    }
    return {
      Com,
      handleClick,
      defaultName,
    }
  }
}
</script>
<style scoped>
main{
  margin-top: 20px;
}
.shop-name{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  text-align: left;
}
.vip-type-list{
  display: flex;
  justify-content: center;
  background-color: #D9EFFF;
  height: 65px;
}
.vip-type-list>div{
  height: 100%;
  line-height: 65px;
  padding:0 55px;
  font-size: 18px;
  color: #333;
}
</style>
<style>
  .vip-type-list>div>div>div>div>div>.el-tabs__item{
    font-size: 16px;
  }
</style>