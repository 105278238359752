<template>
  <div>
    <div class="tab-box">
      <div class="box-header" v-if="dataOne[0]">营业收入：{{Number(dataOne[0].totalIncome).toFixed(2)}}元<span style="font-size:16px;font-weight:400;color:#16AFDC;float: right;margin-right:40px;cursor: pointer;"  @click="this.$router.push({name:'taking',query:this.$route.query})">明细表&nbsp;＞</span></div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="caseClose" label="现金结算" />
          <el-table-column prop="incomeVirtual" label="储值卡结算" />
          <el-table-column prop="bankMoney" label="网银结算" 
          >
            <template #default="scope">
              <span>{{Number(scope.row.bankMoney).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="alipayMoney" label="支付宝结算" />
          <el-table-column prop="wechartMoney" label="微信结算" />
          <el-table-column prop="groupMoney" label="团购结算" />
          <el-table-column prop="shezhang" label="挂账" />
          <el-table-column prop="kdbScanPay" label="扫码支付" />
        </el-table>
      </div>
    </div>
    <div class="tab-box">
      <div class="box-header" v-if="dataOne[0]">优惠折扣（积分抵扣	+	会员优惠）：{{Number(dataOne[0].totalDiscount).toFixed(2)}}元<span style="font-size:16px;font-weight:400;color:#16AFDC;float: right;margin-right:40px;cursor: pointer;" @click="this.$router.push({name:'discounts',query:this.$route.query})">明细表&nbsp;＞</span></div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <!-- <el-table-column prop="discountMoney" label="会员优惠" /> -->
          <el-table-column label="会员优惠"
          >
            <template #default="scope">
              <span>{{Number(scope.row.discountMoney).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="freeMoney" label="免单" />
          <el-table-column prop="reduceMoney" label="扣减" />
          <el-table-column prop="integralDeduct" label="积分抵扣" />
          <el-table-column prop="voucherDeduct" label="代金券消费" />

        </el-table>
      </div>
    </div>
    <div class="tab-box">
      <div class="box-header" v-if="dataOne[0]">净收入：{{Number(dataOne[0].netIncome).toFixed(2)}}元（收款：{{Number(dataOne[0].income).toFixed(2)}} &nbsp;&nbsp;&nbsp;支出：{{Number(dataOne[0].expend).toFixed(2)}}）
        <span style="font-size:16px;font-weight:400;color:#16AFDC;float: right;margin-right:40px;cursor: pointer;"  @click="this.$router.push({name:'income',query:this.$route.query})">明细表&nbsp;＞</span>
      </div>
      <div class="box-bottom" v-if="dataOne[0]">吧台现金：{{((+dataOne[0].cashIncome) + (+dataOne[0].huankuan) + (+dataOne[0].incomeRecharge) - (+dataOne[0].cashoutSum)).toFixed(2)}}元</div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="cashIncome" label="现金消费" />
          <el-table-column prop="huankuan" label="现金还款" />
          <el-table-column prop="incomeRecharge" label="现金充值" />
          <el-table-column prop="cashoutSum" label="现金支出" />
        </el-table>
      </div>
      <div class="box-bottom" v-if="dataOne[0]">线上支付：{{Number(dataOne[0].onlineIncome).toFixed(2)}}元&nbsp;&nbsp;&nbsp;
        <span style="font-size:16px" v-if='dataOne[0]'>手续费：{{(Number(dataOne[0].kdbHandling)).toFixed(2)}}元</span>
      </div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="bankMoney" label="网银结算" />
          <el-table-column prop="bankHuankuan" label="网银还款" />
          <el-table-column prop="bankRecharge" label="网银充值" />
          <el-table-column prop="bankExpend" label="网银支出" />
        </el-table>
      </div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="alipayMoney" label="支付宝结算" />
          <el-table-column prop="alipayHuankuan" label="支付宝还款" />
          <el-table-column prop="alipayRecharge" label="支付宝充值" />
          <el-table-column prop="alipayExpend" label="支付宝支出" />
        </el-table>
      </div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="wechartMoney" label="微信结算" />
          <el-table-column prop="wechartHuankuan" label="微信还款" />
          <el-table-column prop="wechartRecharge" label="微信充值" />
          <el-table-column prop="wechartExpend" label="微信支出" />
        </el-table>
      </div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="kdbScanPay" label="扫码结算" />
          <el-table-column prop="kdbScanHuankuan" label="扫码还款" />
          <el-table-column prop="kdbScanRecharge" label="扫码充值" />
          <el-table-column prop="kdbScanExpend" label="线上支出" />
        </el-table>
      </div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="groupMoney" label="团购结算" />
          <el-table-column prop="groupHuankuan" label="团购还款" />
          <el-table-column prop="groupRecharge" label="团购充值" />
          <el-table-column prop="groupExpend" label="团购支出" />
        </el-table>
      </div>
    </div>
    <div class="tab-box">
    </div>
    <div class="tab-box">
      <div class="box-header"  v-if="dataOne[0]">还款：{{Number(dataOne[0].huankuanIncome).toFixed(2)}}元<span style="font-size:16px;font-weight:400;color:#16AFDC;float: right;margin-right:40px;cursor: pointer;" @click="this.$router.push({name:'vipDetails',query:this.$route.query})">明细表&nbsp;＞</span></div>
      <div>
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="huankuan" label="现金还款" />
          <el-table-column prop="bankHuankuan" label="网银还款" />
          <el-table-column prop="alipayHuankuan" label="支付宝还款" />
          <el-table-column prop="wechartHuankuan" label="微信还款" />
          <el-table-column prop="groupHuankuan" label="团购还款" />
          <el-table-column prop="cardHuankuan" label="储值卡还款" />
          <el-table-column prop="freeHuankuan" label="还款免单" />
          <el-table-column prop="kdbScanHuankuan" label="扫码还款" />
        </el-table>
      </div>
    </div>
    <div class="tab-box">
      <div class="box-header">会员<span style="font-size:16px;font-weight:400;color:#16AFDC;float: right;margin-right:40px;cursor: pointer;" @click="this.$router.push({name:'repayment',query:this.$route.query})">明细表&nbsp;＞</span></div>
      <div>
        <el-table 
          :data="dataTwo" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="newMember" label="新增会员"  />
          <el-table-column prop="membership" label="会员总数"  />
          <!-- <el-table-column prop="date" label="期初余额" /> -->
          <el-table-column label="会员充值">
            <el-table-column prop="memberRechargeGetMoney" label="会员卡充值"  width="120" />
            <el-table-column prop="realMoney" label="充值金额" />
            <el-table-column prop="handselMoney" label="赠送金额" />
            <el-table-column prop="rechargeNumber" label="会员卡充值人数" width="150" />
          </el-table-column>
          <el-table-column label="会员消费">
            <el-table-column prop="expendMoney" label="会员消费" />
            <el-table-column prop="consumeNumber" label="会员卡消费人数" width="150" />
          </el-table-column>
          <!-- <el-table-column prop="date" label="期末余额" /> -->
        </el-table>
      </div>
    </div>
    <Print rul='report/exportDayReport.json' />
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import {postData} from "@a"
import {useRoute} from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const dataOne = ref([
    ])
    const dataTwo = ref([
      {
        date:200
      }
    ])
    // 表格头部样式
    const headerStyle = (row)=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['text-align'] = 'center'
      style['font-weight'] = '400'
      style['background-color'] = '#F2F2F2'
      if(
        row.column.label === '会员卡充值' ||
        row.column.label === '充值金额' ||
        row.column.label === '赠送金额' ||
        row.column.label === '会员卡充值人数' ||
        row.column.label === '会员充值'
      ) style['background-color'] = '#E4E4E4'
      return style
    }
    const cellStyle = ()=>{
      let style  = {}
      style.color = '#000'
      style['font-size'] = '14px'
      style['text-align'] = 'center'
      style['font-weight'] = '500'
      return style
    }
    watch(()=>route.query,()=>{
      if(route.name !== 'aloneShop') return
      let param ={}
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }
      }
      info(param)
    })
    const info = async(param)=>{
      param.shopId = route.query.shopId
      try {
        let res = {data:[]}
        if(route.query.dateType !== '班次'){
          res = await(postData("shop/chainStatistics.json",param))
        }else{
          let data = await(postData("shop/fund_statistics.json",param))
          data.data.shopId = route.query.shopId
          res.data[0] = data.data
        }
          res.data.forEach(e=>{
            if(e.shopId == route.query.shopId){
              e.caseClose = (Number(e.incomeCashMember) + Number(e.incomeCashOther)).toFixed(2)
              dataOne.value[0] = e
            }
          })
      } catch (error) {
        console.log(error)
      }
      try {
        let res = {data:[]}
        if(route.query.dateType !== '班次'){
          res = await(postData("shop/chainMemberStatistics.json",param))
        }else{
          let data = await(postData("report/memberReport.json",param))
          data.data.shopId = route.query.shopId
          res.data[0] = data.data
        }
          res.data.forEach(e=>{
            if(e.shopId == route.query.shopId){
              e.caseClose = (Number(e.incomeCashMember) + Number(e.incomeCashOther)).toFixed(2)
              dataTwo.value[0] = e
            }
          })
      } catch (error) {
        console.log(error)
      }
    }
    let param ={}
    if(route.query.dateType === '年'){
      param.from = route.query.dateMin+'-01-01'
      param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
      param.from = route.query.dateMin
      if(route.query.dateMax){
        param.to = route.query.dateMax
      }
    }
    info(param)
    return {
      dataOne,
      dataTwo,
      headerStyle,cellStyle,
    }
  }
}
</script>
<style scoped>
.tab-box{
  /* background-color: #D7D7D7; */
  margin: 30px 20px 0 20px;
  overflow: hidden;
}
.box-header{
  height: 50px;
  width: 100%;
  line-height: 50px;
  /* font-weight: 700; */
  font-size: 16px;
  padding:0 20px 0 0px;
}
.box-bottom{
  height: 65px;
  width: 100%;
  line-height: 65px;
  /* font-weight: 700; */
  font-size: 16px;
  padding:0 20px;
  background-color: #E4E4E4;
}
</style>