<template>
  <div style="padding:20px">
    <div class="vip-type-list" style="font-size:16px">
      <span>台桌分类汇总：{{headerData.amount}}</span>
      <span>翻台总数：{{headerData.overCounter}}</span>
      <span>到店顾客数：{{headerData.personnum}}</span>
    </div>
    <main>
      <el-table 
       :data="tableData" 
       border  
       style="width: 100%" 
       :row-style="rowStyle"
       :header-cell-style="headerStyle"
      >
        <el-table-column prop="tableName" label="台桌" />
        <el-table-column prop="amount" label="产出金额" />
        <el-table-column prop="output" label="产量%" />
        <el-table-column prop="overCounter" label="翻台数" />
        <el-table-column prop="personnum" label="顾客数" />
      </el-table>
    </main>
        <!-- <Print rul='report/exportTableStatistics.json' /> -->
  </div>
</template>

<script>
import {ref,watch} from 'vue'
import {useRoute} from 'vue-router'
import {postData} from'@a'
export default {
  setup(){
  let route = useRoute()
  let headerData = ref({amount:0,overCounter:0,personnum:0})
  let tableData = ref([])
  const rowStyle =({ row})=>{

    let style={}
    style['text-indent'] = '2em'
    style['font-size'] = '14px'
    if(row.state == 1){
          style['background-color'] = '#F2F2F2'
          style['text-indent'] = '1em'
          style['font-size'] = '14px'
          style['font-weight'] = '600'

    }
    return style
  }
  const headerStyle =()=>{

    let style={}
    style.color = "#333"
    style['font-size'] = '4px'
    style['background-color'] = '#E4E4E4'
    return style
  }
    watch(()=>route.query,()=>{
      if(route.name !== 'aloneShop') return
      let param ={shopId:route.query.shopId}
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        }
      }
      info(param)
    })
    const info = async (param)=>{
      try {
        tableData.value = []
        let res = await postData('shop/table_statistics.json',param)
        headerData.value.amount = res.data.amount
        headerData.value.overCounter = res.data.overCounter
        headerData.value.personnum = res.data.personnum
        for(let i = 0;i< res.data.pageDutyVo.rows.length;i++){
          let state = {sub:0,stuate:false}
          // res.data.pageDutyVo.rows[i].state = 0
          for(let j = 0;j<tableData.value.length;j++){
            if( res.data.pageDutyVo.rows[i].catgoryName === tableData.value[j].catgoryName){
              state.stuate = true
              state.sub = j
              break;
            }
          }
          if(!state.stuate){
            let listData = JSON.stringify(res.data.pageDutyVo.rows[i])
            listData = JSON.parse(listData)
            tableData.value.push(listData)
            tableData.value[tableData.value.length-1].state = 1
            tableData.value[tableData.value.length-1].tableName = res.data.pageDutyVo.rows[i].catgoryName
            tableData.value.push(res.data.pageDutyVo.rows[i])
          }else{
            if(tableData.value.length>0){
              tableData.value.splice(state.sub+1,0,res.data.pageDutyVo.rows[i])
            }else{
              tableData.value.push( res.data.pageDutyVo.rows[i])
            }
            tableData.value[state.sub].amount = Number(tableData.value[state.sub].amount) + ( res.data.pageDutyVo.rows[i].amount)
            tableData.value[state.sub].output = Number(tableData.value[state.sub].output) + ( res.data.pageDutyVo.rows[i].output)
            // tableData.value[state.sub].output = Math.round(Number(tableData.value[state.sub].output) + ( res.data.pageDutyVo.rows[i].output))
            tableData.value[state.sub].overCounter = Number(tableData.value[state.sub].overCounter) + ( res.data.pageDutyVo.rows[i].overCounter)
            tableData.value[state.sub].personnum = Number(tableData.value[state.sub].personnum) + ( res.data.pageDutyVo.rows[i].personnum)
          }
        }
        for(let i = 0;i<tableData.value.length;i++){
          tableData.value[i].output =(tableData.value[i].output?tableData.value[i].output:0).toFixed(2)+'%'
        }
      } catch (error) {
        console.log(error)
      }
    }
    let param ={shopId:route.query.shopId}
    if(route.query.dateType === '年'){
      param.from = route.query.dateMin+'-01-01'
      param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
      param.from = route.query.dateMin
      if(route.query.dateMax){
        param.to = route.query.dateMax
      }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))      }
    }
    info(param)

  return{
    headerData,
    tableData,
    rowStyle,
    headerStyle,
  }
}
}
</script>

<style scoped>
.vip-type-list{
  display: flex;
  /* justify-content: center; */
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 20px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
    font-weight: 600;
}
</style>