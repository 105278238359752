<template>
  <div>
    <main>
      <div class="header">
        <div style="width:200px"><el-input v-model="input1" placeholder="商品名称/简码" /></div> 
        <div>
          <span>商品分类：</span>
          <el-select v-model="value1" placeholder="Select">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>销量：</span>
          <el-select v-model="value2" placeholder="Select">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>已删除：</span>
          <el-select v-model="value3" placeholder="Select">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div  style="width:100px"><el-button @click="reqFun()" type="primary">查询</el-button></div>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="dataOne" 
          border  
          empty-text='数据为空'
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="goodsName" label="商品名称" />
          <el-table-column prop="catgoryName" label="所属分类" />
          <el-table-column prop="goodsUnit" label="单位" />
          <el-table-column prop="sellCount" label="销售中" />
          <el-table-column prop="prevStockCount" label="期初库存" />
          <el-table-column prop="inStockCount" label="入库/盘盈入库" />
          <el-table-column prop="outStockCount" label="出库/盘盈出库" />
          <el-table-column prop="sellStockCount" label="销售数量" />
          <el-table-column prop="stockCount" label="期末库存" />
        </el-table>
      </div>
      <div style="margin:auto;float: right;">
        <el-pagination 
          background 
          height='100'
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
    </main>
        <Print rul='report/exportDayStock.json' :add='printAdd' />
  </div>
</template>

<script>
import {ref,watch} from 'vue'
import {useRoute} from 'vue-router'
import {postData} from'@a'
import {cell} from '@u/cell.js'
export default {
  setup(){
    let route = useRoute()
    let options1 = ref([
      
    ])
    let options2 = ref([
      {value:'全部',label:'全部'},
      {value:'1',label:'有'},
      {value:'2',label:'无'}
    ])
    let options3 = ref([
      {value:'全部',label:'全部'},
      {value:'0',label:'否'},
      {value:'1',label:'是'}
    ])
    let input1 = ref('')
    let value1 = ref('全部')
    let value2 = ref('全部')
    let value3 = ref('全部')
    const dataOne = ref([])
    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '17px'
      style['background-color'] = '#D7D7D7'
      return style
    }
    const cellStyle = ()=>{
      let style  = {}
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      return style
    }
    const reqFun = ()=>{
      console.log(route.query.shopId)
      pagNum.value = 1
      let param ={
        shopId:route.query.shopId,
        pageNo:1,
        pageSize:14, 
      }
      printAdd.value = ''
      if(input1.value) {
        param.q = input1.value
        printAdd.value += '&q=' + input1.value
      }
      if(value1.value != '全部'){
        param.catgoryId = value1.value
        printAdd.value += '&catgoryId=' + value1.value
      }
      if(value2.value != '全部'){
        param.sales = value2.value
        printAdd.value += '&sales=' + value2.value
      }
      if(value3.value != '全部'){
        param.status = value3.value
        printAdd.value += '&status=' + value3.value
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        }
      }
      info(param)
    }
    watch(()=>route.query,()=>{
      if(route.name !== 'aloneShop') return
      infoList()
      reqFun()
    })
    let printAdd = ref('')
    let pagingNum = ref(10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
      let param = {
        shopId:route.query.shopId,
        pageNo:pagNum.value,
        pageSize:14,
      }
      printAdd.value = ''
      if(input1.value) {
        param.q += input1.value
        printAdd.value = '&q=' + input1.value
      }
      if(value1.value != '全部'){
        param.catgoryId = value1.value
        printAdd.value += '&catgoryId=' + value1.value
      }
      if(value2.value != '全部'){
        param.sales = value2.value
        printAdd.value += '&sales=' + value2.value
      }
      if(value3.value != '全部'){
        param.status = value3.value
        printAdd.value += '&status=' + value3.value
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        }
      }
      info(param)
    }
    const info = async(param)=>{
      try {
        let res = await(postData("report/stockReport.json",param))
          if(res.data && res.data.total){
            pagingNum.value = Math.ceil(res.data.total/14)*10
            printAdd.value += '&pageSize=' + res.data.total
            res.data.rows.forEach(e => {
              e.goodsUnit = cell(e.goodsUnit)
            });
            dataOne.value = res.data.rows
          }else{
            dataOne.value = []
          }

      } catch (err) {
                dataOne.value = []
        console.log(err)

      }
    }
    let param ={
      shopId:route.query.shopId,
      pageNo:1,
      pageSize:14,  
    }
    if(route.query.dateType === '年'){
      param.from = route.query.dateMin+'-01-01'
      param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
      param.from = route.query.dateMin
      if(route.query.dateMax){
        param.to = route.query.dateMax
      }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
      }
    }
    
    const infoList = async()=>{
      try {
        options1.value = [{value:'全部',label:'全部'}]
        let res = await(postData("goods/catgory.json",{shopId:route.query.shopId}))
          for(let i =0;i<res.data.length;i++){
            options1.value.push({label:res.data[i].catgoryName,value:res.data[i].catgoryId})
          }
      } catch (error) {
        console.log(error)
      }
    }
    infoList()
    info(param)
    return{
      reqFun,
      pagingNum,
      pagNum,
      pagingReq,
      options1,
      options2,
      options3,
      input1,
      value1,
      value2,
      value3,
      dataOne,
      headerStyle,cellStyle,
      printAdd,

    }
  }
}
</script>

<style scoped>
main{
  margin: 25px;
}
.header{
  display: flex;
  /* justify-content: flex-start; */
    flex-wrap: wrap;
    font-size: 16px;
}
.header>div{
  padding-right: 20px;
  width: 300px;
}
</style>