<template>
  <div style="padding:20px">
    <div style="width:200px;padding-bottom:20px;">
      <el-input placeholder="商品名称、简码" v-model="VipCard"></el-input>
      &nbsp;
      &nbsp;
      &nbsp;
      <el-button type="primary" @click="reqFun()">查询</el-button>
    </div>
    <div class="vip-type-list" style="font-size:16px">
      <span>存茶数量：{{headerNum.allStock}}</span>
      <span>存茶金额：{{headerNum.allPrice}}</span>
    </div>
    <main>
      <el-table 
       :data="tableData" 
       border  
       style="width: 100%" 
       :row-style="rowStyle"
       :header-cell-style="headerStyle"
       :cell-style="cellStyle"
      @cell-click="clickFun"
      >
        <el-table-column prop="goodsName" label="商品名称" />
        <el-table-column prop="catgoryName" label="分类" />
        <el-table-column prop="goodsUnit" label="单位" />
        <el-table-column prop="stockCount" label="存茶数量" />
        <el-table-column prop="allPrice" label="存茶金额" />
        <el-table-column prop="check" label="操作" />
      </el-table>
    </main>
    <el-dialog
      v-model="dialogState"
      title="商品名：存茶明细"
      width="50%"
    >
      <div>
        <el-table :data="goodsTableData" style="width: 100%">
          <el-table-column prop="goodsName" label="商品名称"/>
          <el-table-column prop="stockCount" label="剩余数量"/>
          <el-table-column prop="goodsUnit" label="单位" />
          <el-table-column prop="memberName" label="会员姓名" />
          <el-table-column prop="memberPhone" label="会员电话" />
          <el-table-column prop="memberCardNumber" label="会员卡号" />
        </el-table>
      </div>
      <template #footer>
        <span class="dialog-footer">
        </span>
      </template>
    </el-dialog>
    <div style="margin:auto;float: right;">
      <el-pagination 
        background 
        height='100'
        @current-change='pagingReq' 
        layout="prev, pager, next" 
        :current-page="pagNum"
        :total="pagingNum">
      </el-pagination>
    </div>
        <!-- <Print rul='report/exportMemberStock.json' :add='printAdd' /> -->
  </div>
</template>

<script>
import {ref,watch} from 'vue'
import {useRoute} from 'vue-router'
import {postData} from'@a'
import {cell} from '@u/cell.js'
export default {
  setup(){
    let route = useRoute()
  let dialogState = ref(false)  //弹出框状态
  let tableData = ref([])
  let goodsTableData = ref() //存茶详情表格数据)
  let VipCard = ref()
  let printAdd = ref('')
  const rowStyle =()=>{
    let style={}
    style['text-indent'] = '2em'
    style['font-size'] = '14px'
    return style
  }
    const cellStyle = ({ row, column })=>{
      if(!row)console.log(1)
      let style  = {}
    if(column.property === 'check' && column.label === '操作'){
      style.cursor= 'pointer'
      style.color = '#619BD8'
    }
    return style
  }
  const headerStyle =()=>{
    let style={}
    style.color = "#333"
    style['font-size'] = '14px'
    style['background-color'] = '#E4E4E4'
    return style
  }
  const lookGoods = async(param)=>{
    try {
      let res = await(postData("report/memberStockDetail.json",param))
      res.data.forEach(e=>{
        e.goodsUnit = cell(e.goodsUnit)
      })
      goodsTableData.value = res.data
    } catch (error) {
      console.log(error)
    }
  }
  const clickFun = (row,column)=>{
      if(!row)console.log(1)
      if(column.label==="操作"){
        dialogState.value = true
        lookGoods({goodsId:row.goodsId})
      }
    }
    let phone = ref('')
    const reqFun = ()=>{
      pagNum.value = 1
      let param ={
        shopId:route.query.shopId,
        pageNo:1,
        pageSize:14, 
      }
      if(phone.value.length>0){
        param.q = phone.value
      }
      printAdd.value = ''
      if(VipCard.value != '') {
        param.q = VipCard.value
        printAdd.value += '&q'+VipCard.value
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
       }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))        }
      }
      info(param)
      infoHeaderNum(param)
    }
    watch(()=>route.query,(a,b)=>{
      if(route.name !== 'aloneShop') return
      if(a.shopId != b.shopId){
        reqFun()
      }
    })
    let pagingNum = ref(10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
      let param = {
        shopId:route.query.shopId,
        pageNo:pagNum.value,
        pageSize:14,
      }
      if(phone.value.length>0){
        param.q = phone.value
      }
      printAdd.value = ''
      if(VipCard.value != '') {
        param.q = VipCard.value
        printAdd.value += '&q'+VipCard.value
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))        }
      }
      info(param)
      infoHeaderNum(param)
    }
    const info = async(param)=>{
      try {
        let res = await(postData("report/memberStock.json",param))
        pagingNum.value = Math.ceil(res.data.total/14)*10
          printAdd.value += '&pageSize='+res.data.total
          tableData.value = res.data.records
          tableData.value.forEach((e) => {
            e.goodsUnit = cell(e.goodsUnit)
            e.check = '查看'
          });
      } catch (error) {
        console.log(error)
      }
    }
    let headerNum = ref({})
    const infoHeaderNum = async(param)=>{
      try {
        let res = await(postData("report/sumMemberStock.json",param))
        headerNum.value = res.data
      } catch (error) {
        console.log(error)
      }
    }
    let param ={
      shopId:route.query.shopId,
      pageNo:1,
      pageSize:14,  
    }
    if(route.query.dateType === '年'){
      param.from = route.query.dateMin+'-01-01'
      param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
      param.from = route.query.dateMin
      if(route.query.dateMax){
        param.to = route.query.dateMax
      }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))      }
    }
    info(param)
    infoHeaderNum(param)
    return{
      VipCard,
      phone,
      reqFun,
      pagingNum,
      pagNum,
      pagingReq,
      infoHeaderNum,
      headerNum,
    tableData,
    rowStyle,
    headerStyle,
    cellStyle,
    clickFun,
    dialogState,
    goodsTableData,
    printAdd,
  }
}
}
</script>

<style scoped>
.vip-type-list{
  display: flex;
  /* justify-content: center; */
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 20px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
    font-weight: 600;
}
</style>