<template>
  <div style="padding:20px">
    <div style="">  
      <el-radio-group v-model="isCollapse" @change='changeFun' style="margin-bottom: 20px">
        <el-radio-button :label="false" >员工</el-radio-button>
        <el-radio-button :label="true" >分类</el-radio-button>
      </el-radio-group>
    </div>
    <div class="vip-type-list" style="font-size:16px">
      <span>销售总额：{{Number(salesAmount).toFixed(2)}}    提成金额：{{Number(royalty).toFixed(2)}}</span>
      <span>（ 商品提成：{{Number(goodsRoyalty).toFixed(2)}}    服务提成：{{Number(serviceRoyalty).toFixed(2)}}）</span>
    </div>
    <main v-if='!isCollapse'>
      <el-table 
       :data="tableData" 
       border  
       style="width: 100%" 
       :row-style="rowStyle"
       :header-cell-style="headerStyle"
       :cell-style="cellStyle"
        @cell-click="clickFun"
      >
        <el-table-column prop="userName" label="姓名" />
        <el-table-column prop="staffType" label="角色" />
        <el-table-column label="销售金额" >
          <template #default="props">
            <span>{{(Number(props.row.salesAmount)).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="royalty" label="提成金额" />
        <el-table-column prop="goodsRoyalty" label="商品提成" />
        <el-table-column prop="serviceRoyalty" label="服务提成" />        
        <el-table-column prop="check" label="操作" />
      </el-table>
              <Print rul='report/exportAchievementWaiter.json' />
    </main>
    <main v-else>
      <el-table 
        :data="tableDataTwo" 
        border  
        style="width: 100%" 
        :row-style="rowStyleTwo"
        :header-cell-style="headerStyleTwo"
        :cell-style="cellStyleTwo"
        @cell-click="clickFunTwo"
      >
        <el-table-column prop="itemName" label="名称" />
        <el-table-column prop="saleCount" label="销售数量" />
        <el-table-column prop="salesAmount" label="销售金额" >
          <template #default="props">
            <span>{{(Number(props.row.salesAmount)).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="royalty" label="提成金额" />
        <el-table-column prop="check" label="操作" />
      </el-table>
              <Print rul='report/exportAchievementCate.json' />
    </main>
  </div>
</template>

<script>
import {ref,watch} from'vue'
import {useRouter,useRoute} from'vue-router'
import {postData} from'@a'
export default {
setup(){
  // 提成中的
  const router = useRouter()
  const route = useRoute()
  let isCollapse = ref(false)
  let tableData = ref([])

  const rowStyle =()=>{
    let style={}
    style['text-indent'] = '2em'
    style['font-size'] = '14px'
    return style
  }
  const cellStyle = ({ row, column })=>{
      if(!row)console.log(1)
      let style  = {}
    if(column.property === 'check' && column.label === '操作'){
      style.cursor= 'pointer'
      style.color = '#619BD8'
    }
    return style
  }
  const headerStyle =()=>{
    let style={}
    style.color = "#333"
    style['font-size'] = '14px'
    style['background-color'] = '#E4E4E4'
    return style
  }
  const clickFun = (row, column)=>{
    if(!row)console.log(1)
    if(column.label==="操作"){
      router.push({name:'deductStaff',query:{name:row.userName,waiterId:row.userId,dateType:route.query.dateType,shopId:route.query.shopId,dateMin:route.query.dateMin,dateMax:route.query.dateMax}})
    }
  }
  const changeFun = ()=>{
    reqFun()
  }
// 分类中的
  let tableDataTwo = ref([])
  const rowStyleTwo =({ row})=>{
    let style={}
    style['text-indent'] = '2em'
    style['font-size'] = '14px'
    if(row.state == 1){
      style['background-color'] = '#F2F2F2'
      style['text-indent'] = '1em'
      style['font-size'] = '14px'
      style['font-weight'] = '600'
    }
    return style
  }
  const headerStyleTwo =()=>{
    let style={}
    style.color = "#333"
    style['font-size'] = '14px'
    style['background-color'] = '#E4E4E4'
    return style
  }
  const cellStyleTwo = ({ row, column })=>{
    if(!row)console.log(1)
      let style  = {}
    if(column.property === 'check' && column.label === '操作'){
      style.cursor= 'pointer'
      style.color = '#619BD8'
    }
    return style
  }
  const clickFunTwo = (row, column)=>{
    if(column.label==="操作" && row.state != 1){
      router.push({name:'deductGoods',query:{name:row.itemName,entityId:row.entityId,dateType:route.query.dateType,shopId:route.query.shopId,dateMin:route.query.dateMin,dateMax:route.query.dateMax,type:row.itemType}})
    }
  }
    const reqFun = ()=>{
      let param ={
        shopId:route.query.shopId,
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyId = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))        }
      }
      let url = ''
      if(isCollapse.value){
        url = 'report/achievement/cate.json'
      }else{
        url = 'report/achievement/index.json'
      }
      info(url,param)
    }
    watch(()=>route.query,()=>{
      if(route.name === 'aloneShop')reqFun()

    })
      // <span>销售总额：{{salesAmount}}    提成金额：{{mealRoyalty}}</span>
      // <span>（ 商品提成：{{goodsRoyalty}}    服务提成：{{serviceRoyalty}}）</span>
  let salesAmount = ref(0)
  let royalty = ref(0)
  let goodsRoyalty = ref(0)
  let serviceRoyalty = ref(0)
  const info = async(url,param)=>{
    tableData.value = []
    tableDataTwo.value = []
    try {
      if(url === 'report/achievement/index.json'){
        salesAmount.value = 0
        royalty.value = 0
        goodsRoyalty.value = 0
        serviceRoyalty.value = 0
        let res = await(postData(url,param))
        for(let i = 0;i<res.data.length;i++){
          for (var obj in  res.data[i].users[0]) {
              res.data[i][obj] = res.data[i].users[0][obj];
              if(obj == 'staffType'){
                res.data[i][obj] = res.data[i][obj]==0?'服务员':res.data[i][obj]==1?'收银员':res.data[i][obj]==2?'经理':'老板'
              }
              
          }
        }
        tableData.value = res.data
        tableData.value.forEach((e) => {
          salesAmount.value += Number(e.salesAmount)
          royalty.value += Number(e.royalty)
          goodsRoyalty.value += Number(e.goodsRoyalty)
          serviceRoyalty.value += Number(e.serviceRoyalty)
          e.check = '查看'
        });
      }else{
        salesAmount.value = 0
        royalty.value = 0
        goodsRoyalty.value = 0
        serviceRoyalty.value = 0
        tableDataTwo.value = []
        param.type = 0
        let res1 = await(postData(url,param))
        param.type = 1
        let res2 = await(postData(url,param))
        param.type = 2
        let res3 = await(postData(url,param))
        tableDataTwo.value = []
          tableDataTwo.value.push({itemName:'商品',state:1,saleCount:0,salesAmount:0,royalty:0})
          let sub = 0
          for(let i = 0;i<res1.data.item.length;i++){
            tableDataTwo.value.push(res1.data.item[i])
            tableDataTwo.value[sub].saleCount = Number(tableDataTwo.value[sub].saleCount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].saleCount)
            tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount)
            tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty) + Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty)
          }
          tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount).toFixed(2)
          tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty).toFixed(2)
          tableDataTwo.value.push({itemName:'服务',state:1,saleCount:0,salesAmount:0,royalty:0})
          sub = tableDataTwo.value.length - 1
          for(let i = 0;i<res2.data.item.length;i++){
            tableDataTwo.value.push(res2.data.item[i])
            tableDataTwo.value[sub].saleCount = Number(tableDataTwo.value[sub].saleCount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].saleCount)
            tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount)
            tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty) + Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty)
          }
          tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount).toFixed(2)
          tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty).toFixed(2)
          tableDataTwo.value.push({itemName:'套餐',state:1,saleCount:0,salesAmount:0,royalty:0})
          sub = tableDataTwo.value.length - 1
          for(let i = 0;i<res3.data.item.length;i++){
            tableDataTwo.value.push(res3.data.item[i])
            tableDataTwo.value[sub].saleCount = Number(tableDataTwo.value[sub].saleCount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].saleCount)
            tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount) + Number(tableDataTwo.value[tableDataTwo.value.length-1].salesAmount)
            tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty) + Number(tableDataTwo.value[tableDataTwo.value.length-1].royalty)
          }
          tableDataTwo.value[sub].salesAmount = Number(tableDataTwo.value[sub].salesAmount).toFixed(2)
          tableDataTwo.value[sub].royalty = Number(tableDataTwo.value[sub].royalty).toFixed(2)
          tableDataTwo.value.push({itemName:'充值',state:1,saleCount:0,salesAmount:0,royalty:0})
          tableDataTwo.value.push({itemName:'预约',state:1,saleCount:0,salesAmount:0,royalty:0})

          tableDataTwo.value.forEach((e) => {
            //  e.check = '查看'
            if(!e.state){
              e.check = '查看'
            }else{
              salesAmount.value += Number(e.salesAmount)
              royalty.value += Number(e.royalty)
              if(e.itemName === '商品') goodsRoyalty.value = e.royalty
              if(e.itemName === '服务') serviceRoyalty.value = e.royalty
            }
          });
      }
    } catch (error) {
      console.log(error)
    }
  }
  reqFun()
  return{
    isCollapse,
    tableData,
    rowStyle,
    headerStyle,
    cellStyle,
    clickFun,
    changeFun,
    tableDataTwo,
    rowStyleTwo,
    headerStyleTwo,
    cellStyleTwo,
    clickFunTwo,
    salesAmount,
    royalty,
    goodsRoyalty,
    serviceRoyalty,
  }
}
}
</script>


<style scoped>
.vip-type-list{
  display: flex;
  /* justify-content: center; */
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 20px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}
</style>