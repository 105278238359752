<template>
  <div>
    <main>
      <div class="vip-type-list" style="font-size:16px">
        <span>销售总金额:{{(Number(alterMoney)).toFixed(2)}}</span>
        <span>其中包含:扣减{{(Number(headerData.reduceeMoney)).toFixed(2)}}元 , 免单{{(Number(headerData.freeMoney)).toFixed(2)}}元 , 代金券{{(Number(headerData.voucherMoney)).toFixed(2)}}元</span>
      </div>
      <div>
        <div class="z-table">
          <div class="tab-header">
            <div><div>&nbsp;项目名称</div></div>
            <div><div>&nbsp;数量</div></div>
            <div><div>&nbsp;其中配送</div></div>
            <div><div>&nbsp;销售金额</div></div>
            <div><div>&nbsp;会员折扣</div></div>
            <div><div>&nbsp;折后价</div></div>
            <div><div>&nbsp;免单</div></div>
          </div>
          <div class="tab-main" v-for="(v,i) in dataOne" :key="i" :style="i===0?'background-color:#E5E5E5;font-weight:600':''">
            <div class="tab-th">
              <div>&nbsp;&nbsp;
                <span v-show="!v.state && i!==0" @click="unfold(v,true)" style="font-weight:700;font-size:17px;cursor: pointer;">{{i!==0?'➕':''}}</span>
                <span v-show="v.state && i!==0" @click="unfold(v,false)" style="font-weight:700;cursor: pointer;font-size:17px">➖</span>
                &nbsp;{{v.goodsCatgoryName}}
              </div>
            </div>
            <div class="tab-th"><div>&nbsp;{{v.saleCount}}</div></div>
            <div class="tab-th"><div>&nbsp;{{v.freeSaleCount}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.itemMoney.toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{(v.itemMoney - v.saleMoney).toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.saleMoney.toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.freeMoney.toFixed(2)}}</div></div>
            <div v-show="v.state && i!==0" class="sub-table">
              <div v-for="(subv,subi) in v.data" :key="subi" :style="subi%2?'background-color:#f5f5f5':''">
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;{{subv.goodsName}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;{{subv.saleCount}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;{{subv.freeSaleCount}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;￥{{subv.itemMoney.toFixed(2)}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;￥{{(subv.itemMoney-subv.saleMoney).toFixed(2)}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;￥{{subv.saleMoney.toFixed(2)}}</div></div>
                <div><div>&nbsp;￥{{subv.freeMoney.toFixed(2)}}</div></div>
              </div>
            </div>
          </div>
          <div class="tab-main" v-for="(v,i) in dataTwo" :key="i" :style="i===0?'background-color:#E5E5E5;font-weight:600':''">
            <div class="tab-th">
              <div>&nbsp;&nbsp;
                <span v-show="!v.state && i!==0" @click="unfold(v,true)" style="font-weight:700;font-size:17px;cursor: pointer;">{{i!==0?'➕':''}}</span>
                <span v-show="v.state && i!==0" @click="unfold(v,false)" style="font-weight:700;cursor: pointer;font-size:17px">➖</span>
                &nbsp;{{v.serviceCatgoryName}}
              </div>
            </div>
            <div class="tab-th"><div>&nbsp;{{v.saleCount.toFixed(2)}}小时</div></div>
            <div class="tab-th"><div>&nbsp;{{v.freeSaleCount}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.itemMoney.toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{(v.itemMoney - v.saleMoney).toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.saleMoney.toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.freeMoney.toFixed(2)}}</div></div>
            <div v-show="v.state && i!==0" class="sub-table">
              <div v-for="(subv,subi) in v.data" :key="subi" :style="subi%2?'background-color:#f5f5f5':''">
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;{{subv.serviceName}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;{{subv.saleCount.toFixed(2)}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;{{subv.freeSaleCount}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;￥{{subv.itemMoney.toFixed(2)}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;￥{{(subv.itemMoney-subv.saleMoney).toFixed(2)}}</div></div>
                <div><div style="border-right:1px solid #d7d7d7">&nbsp;￥{{subv.saleMoney.toFixed(2)}}</div></div>
                <div><div>&nbsp;￥{{subv.freeMoney.toFixed(2)}}</div></div>
              </div>
            </div>
          </div>
          <div class="tab-main" v-for="(v,i) in dataThree" :key="i" :style="i===0?'background-color:#E5E5E5;font-weight:600':''">
            <div class="tab-th"><div>&nbsp;&nbsp;&nbsp;&nbsp;{{v.setmealName}}</div></div>
            <div class="tab-th"><div>&nbsp;{{v.saleCount}}</div></div>
            <div class="tab-th"><div>&nbsp;{{v.freeSaleCount}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.itemMoney.toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{(v.itemMoney - v.saleMoney).toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.saleMoney.toFixed(2)}}</div></div>
            <div class="tab-th"><div>&nbsp;￥{{v.freeMoney.toFixed(2)}}</div></div>
          </div>
        </div>
      </div>
      
    </main>
        <Print rul='report/exportAnalysisReport.json' />
  </div>
</template>

<script>
import { ref ,watch } from 'vue'
import {postData} from'@a'
import {useRoute} from 'vue-router'
export default {
  setup() {
    let route = useRoute()

    const dataOne = ref([])
    const dataTwo = ref([])
    const dataThree = ref([])
    const alterMoney = ref(0)
    // 表格头部样式
    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '600'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      return style
    }
    const cellStyle = ()=>{
      let style  = {}
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      return style
    }
    const rowClass = ({ row, rowIndex })=>{
      if(row.state && rowIndex === 0){
        return 'header-row'
      }
    }
    watch(()=>route.query,()=>{
      if(route.name !== 'aloneShop') return
      let param ={shopId:route.query.shopId}
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        }
      }
      infoGoods(param)
      infoService(param)
      infoSetmeal(param)
      infoDiscount(param)
    })
    const infoGoods = async (param)=>{
      alterMoney.value = 0
      try {
        let res = await postData('report/analysisReportByGoods.json',param)
        let resData = JSON.stringify(res.data)
        resData = JSON.parse(resData)
        dataOne.value = []
        dataOne.value.push({goodsCatgoryName:'商品类',saleCount:0,freeSaleCount:0,itemMoney:0,freeMoney:0,saleMoney:0,channelMoney:0,state:1})
          for(let i = 0;i<res.data.length;i++){
            let state = {sub:0,stuate:false}
            dataOne.value[0].saleCount += Number(res.data[i].saleCount)
            dataOne.value[0].freeSaleCount += Number(res.data[i].freeSaleCount)
            dataOne.value[0].itemMoney += Number(res.data[i].itemMoney)
            dataOne.value[0].freeMoney += Number(res.data[i].freeMoney)
            dataOne.value[0].saleMoney += Number(res.data[i].saleMoney)
            dataOne.value[0].channelMoney += Number(res.data[i].channelMoney)
            dataOne.value[0].itemMoney =  +(Number(dataOne.value[0].itemMoney).toFixed(2))
            dataOne.value[0].freeMoney =  +(Number(dataOne.value[0].freeMoney).toFixed(2))
            dataOne.value[0].saleMoney =  +(Number(dataOne.value[0].saleMoney).toFixed(2))
            dataOne.value[0].channelMoney =  +(Number(dataOne.value[0].channelMoney).toFixed(2))
            for(let j = 0;j<dataOne.value.length;j++){
              if(res.data[i].goodsCatgoryName === dataOne.value[j].goodsCatgoryName){
                state.stuate = true
                state.sub = j
              }
            }
            if(!state.stuate){
              dataOne.value.push(res.data[i])
            }else{
              if(!dataOne.value[state.sub].data){
                dataOne.value[state.sub].data = []
              }
              dataOne.value[state.sub].data.push(res.data[i])
              dataOne.value[state.sub].saleCount = Number(dataOne.value[state.sub].saleCount) + (res.data[i].saleCount)
              dataOne.value[state.sub].freeSaleCount = Number(dataOne.value[state.sub].freeSaleCount) + (res.data[i].freeSaleCount)
              dataOne.value[state.sub].itemMoney = Number(dataOne.value[state.sub].itemMoney) + (res.data[i].itemMoney)
              dataOne.value[state.sub].freeMoney = Number(dataOne.value[state.sub].freeMoney) + (res.data[i].freeMoney)
              dataOne.value[state.sub].saleMoney = Number(dataOne.value[state.sub].saleMoney) + (res.data[i].saleMoney)
              dataOne.value[state.sub].channelMoney = Number(dataOne.value[state.sub].channelMoney) + (res.data[i].channelMoney)
            }
          }
          alterMoney.value += dataOne.value[0].saleMoney
          for(let i = 1;i<dataOne.value.length;i++){
            for(let j = 0;j<resData.length;j++){
              if(dataOne.value[i].goodsId === resData[j].goodsId){
                if(!dataOne.value[i].data)dataOne.value[i].data = []
                dataOne.value[i].data.unshift(resData[j])
                break;
              }

            }
          }
      } catch (error) {
        console.log(error)
      }
    }
    const infoService = async (param)=>{
      try {
        let res = await postData('report/analysisReportByService.json',param)
        let resData = JSON.stringify(res.data)
        resData = JSON.parse(resData)
        dataTwo.value = []
        dataTwo.value.push({serviceCatgoryName:'服务类',saleCount:0,freeSaleCount:0,itemMoney:0,freeMoney:0,saleMoney:0,channelMoney:0,state:1})
          for(let i = 0;i<res.data.length;i++){
            dataTwo.value[0].saleCount += +(Number(res.data[i].saleDuration).toFixed(2))
            dataTwo.value[0].freeSaleCount += Number(res.data[i].freeSaleCount)
            dataTwo.value[0].itemMoney += Number(res.data[i].itemMoney)
            dataTwo.value[0].freeMoney += Number(res.data[i].freeMoney)
            dataTwo.value[0].saleMoney += Number(res.data[i].saleMoney)
            dataTwo.value[0].channelMoney += Number(res.data[i].channelMoney)
            let state = {sub:0,stuate:false}
            for(let j = 0;j<dataTwo.value.length;j++){
              if(res.data[i].serviceCatgoryName === dataTwo.value[j].serviceCatgoryName){
                state.stuate = true
                state.sub = j
              }
            }
            if(!state.stuate){
              res.data[i].saleCount = res.data[i].saleDuration
              dataTwo.value.push(res.data[i])
            }else{
              if(!dataTwo.value[state.sub].data){
                dataTwo.value[state.sub].data = []
              }
              res.data[i].saleCount = res.data[i].saleDuration
              dataTwo.value[state.sub].data.push(res.data[i])
              dataTwo.value[state.sub].saleCount = Number(dataTwo.value[state.sub].saleCount) + (res.data[i].saleDuration)
              dataTwo.value[state.sub].freeSaleCount = Number(dataTwo.value[state.sub].freeSaleCount) + (res.data[i].freeSaleCount)
              dataTwo.value[state.sub].itemMoney = Number(dataTwo.value[state.sub].itemMoney) + (res.data[i].itemMoney)
              dataTwo.value[state.sub].freeMoney = Number(dataTwo.value[state.sub].freeMoney) + (res.data[i].freeMoney)
              dataTwo.value[state.sub].saleMoney = Number(dataTwo.value[state.sub].saleMoney) + (res.data[i].saleMoney)
              dataTwo.value[state.sub].channelMoney = Number(dataTwo.value[state.sub].channelMoney) + (res.data[i].channelMoney)
            }
          }
          alterMoney.value += dataTwo.value[0].saleMoney
          for(let i = 1;i<dataTwo.value.length;i++){
            for(let j = 0;j<resData.length;j++){
              if(dataTwo.value[i].serviceId === resData[j].serviceId){
                if(!dataTwo.value[i].data)dataTwo.value[i].data = []
                resData[j].saleCount =resData[j].saleDuration
                dataTwo.value[i].data.unshift(resData[j])
                break;
              }
            }
          }
      } catch (error) {
        console.log(error)
      }
    }
    const infoSetmeal = async (param)=>{
      try {
        let res = await postData('report/analysisReportBySetmeal.json',param)
        dataThree.value = []
        dataThree.value.push({setmealName:'套餐类',saleCount:0,freeSaleCount:0,itemMoney:0,freeMoney:0,saleMoney:0,channelMoney:0,state:1})
          for(let i = 0;i<res.data.length;i++){
            dataThree.value[0].saleCount += Number(res.data[i].saleCount)
            dataThree.value[0].freeSaleCount += Number(res.data[i].freeSaleCount)
            dataThree.value[0].itemMoney += Number(res.data[i].itemMoney)
            dataThree.value[0].freeMoney += Number(res.data[i].freeMoney)
            dataThree.value[0].saleMoney += Number(res.data[i].saleMoney)
            dataThree.value[0].channelMoney += Number(res.data[i].channelMoney)
                       let state = {sub:0,stuate:false}
            for(let j = 0;j<dataThree.value.length;j++){
              if(res.data[i].setmealName === dataThree.value[j].setmealName){
                state.stuate = true
                state.sub = j
              }
            }
            if(!state.stuate){
              dataThree.value.push(res.data[i])
            }else{
              if(!dataThree.value[state.sub].data){
                dataThree.value[state.sub].data = []
              }
              dataThree.value[state.sub].data.push(res.data[i])
              dataThree.value[state.sub].saleCount = Number(dataThree.value[state.sub].saleCount) + (res.data[i].saleCount)
              dataThree.value[state.sub].freeSaleCount = Number(dataThree.value[state.sub].freeSaleCount) + (res.data[i].freeSaleCount)
              dataThree.value[state.sub].itemMoney = Number(dataThree.value[state.sub].itemMoney) + (res.data[i].itemMoney)
              dataThree.value[state.sub].freeMoney = Number(dataThree.value[state.sub].freeMoney) + (res.data[i].freeMoney)
              dataThree.value[state.sub].saleMoney = Number(dataThree.value[state.sub].saleMoney) + (res.data[i].saleMoney)
              dataThree.value[state.sub].channelMoney = Number(dataThree.value[state.sub].channelMoney) + (res.data[i].channelMoney)
            }
          }
          alterMoney.value += dataThree.value[0].saleMoney
      } catch (error) {
        console.log(error)
      }
    }
    let headerData = ref('')
    const infoDiscount = async (param)=>{
      try {
        let res = await postData('report/analysisReportDiscount.json',param)
        headerData.value = res.data
      } catch (error) {
        console.log(error)
      }
    }
    // 销售总金额
    let param ={shopId:route.query.shopId}
    if(route.query.dateType === '年'){
      param.from = route.query.dateMin+'-01-01'
      param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
      param.from = route.query.dateMin
      if(route.query.dateMax){
        param.to = route.query.dateMax
      }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
      }
    }
    const unfold = (row,state)=>{
      console.log(row)
      if(state){
        row.state = true
      }else{
        row.state = false
      }
    }
    infoGoods(param)
    infoService(param)
    infoSetmeal(param)
    infoDiscount(param)
    let kong = ref([])
    return {
      kong,
      dataOne,
      headerStyle,
      cellStyle,
      dataTwo,
      dataThree,
      rowClass,
      headerData,
      alterMoney,
      unfold,
    }
  }

}
</script>

<style scoped>
main{
  margin: 25px;
}
.shop-name{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  text-align: left;
}
.vip-type-list{
  display: flex;
  /* justify-content: center; */
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 20px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
}
.vip-type-list>span:nth-child(1){
  font-weight: 600;
}
.tab-main>div ,.tab-header>div {
  width: 14.28%;
  /* flex: 1; */
  height: 45px;
  line-height: 45px;
}
.z-table>div>div>div{
  border: 1px solid #e9e9e9;
  /* border-top: none; */
}
.z-table{
  font-size: 14px;
}
.tab-main{
  position: relative;
  display:flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.tab-th{
  /* background-color:#F2F2F2 */
  overflow: hidden;
}
.tab-main>.sub-table{
  width: 100%;
  padding: 10px;
  height: auto;
}
.sub-table>div{
  display: flex;
}
.tab-main>.sub-table>div>div{
  width: 14.28%;
}
.tab-header{
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  background-color: #C9C9C9;
  height: 45px;
  line-height: 45px;
  display:flex;
}



</style>
<style >
.el-table__body>tbody>.header-row{
  background-color: #e9e9e9;
  font-weight: 600;
}
tbody>.header-row>td:first-child{
  visibility: hidden;
}

</style>