<template>
  <div>
    <main>
      <div class="header">
        <div>
          <span>台桌：</span>
          <el-select v-model="value1" placeholder="Select">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>操作人；</span>
          <el-select v-model="value4" placeholder="Select">
            <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div  style="width:100px"><el-button @click="pagingReq(1)" type="primary">查询</el-button></div>
      </div>
      <div class="vip-type-list" style="font-size:16px">
        <span>总计收入：{{revenuesAllMoney?revenuesAllMoney:'0.00'}}</span>
        <span>总计支出：{{expendAllMoney?expendAllMoney:'0.00'}}</span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
          @cell-click="clickFun"
        >
          <el-table-column prop="detailTime" label="时间" />
          <el-table-column prop="tableName" label="台桌" />
          <el-table-column prop="incomeName" label="类型" />
          <el-table-column prop="incomeTypeText" label="支付方式"/>
          <el-table-column prop="orderSerial" label="编号" width="400"  />
          <el-table-column prop="incomeMoney" label="金额" />
          <el-table-column prop="handleName" label="操作人" />
          <el-table-column prop="check" label="操作" />
        </el-table>
      </div>
      <div style="margin:auto;float: right;">
        <el-pagination 
          background 
          height='100'
          @current-change='pagingReq' 
          layout="prev, pager, next" 
          :current-page="pagNum"
          :total="pagingNum">
        </el-pagination>
      </div>
    </main>
    <!-- 台桌消费弹出框1 -->
    <el-dialog v-model="outerVisible" width="75%">
      <template #default>
        <div id="tabOne" >
          <div v-for="i in tableDatail" :key="i">
            <div style="font-size:16px;padding:20px">
              <p>台号：{{i.tableName}}    翻台次数：{{i.tableCounter}}</p>
              <p>开始时间：{{i.beginTime}}    结算时间：{{i.endTime}}  编号：{{i.orderSerial}}</p>
            </div>
            <el-table :data="i.rows" style="width: 100%">
              <el-table-column prop="itemName" label="商品名称" width="180" />
              <el-table-column prop="itemCount" label="数量" width="180" />
              <el-table-column prop="itemUnit" label="单位" />
              <el-table-column prop="itemMoney" label="单价" />
              <el-table-column prop="itemDiscount" label="优惠折扣" />
              <el-table-column prop="finalMoney" label="折后价" />
            </el-table>       
          </div>
          <div>
            <p>合计：{{(tableTotal.orderMoney-0).toFixed(2)}}  &nbsp;&nbsp;优惠：{{(tableTotal.totalDiscount-0).toFixed(2)}}&nbsp;&nbsp;折扣：￥{{(tableTotal.reduceMoney-0).toFixed(2)}}</p>
            <p>应收：{{(tableTotal.finalMoney-0).toFixed(2)}}</p>
            <p style="font-size:18px;white-space: pre-wrap;">其中：{{tableTotal.incomeMoneyText}}</p>
            <p>结算人：{{tableTotal.payUserName}}</p>
            <p>备注：{{tableTotal.orderDesc}}</p>
          </div>
        </div>
        
      </template>
      <template #footer>
        <div class="dialog-footer">
          <el-button v-if="splitType !== 1" @click="alterTableData()">修改支付渠道</el-button>
          <el-button v-if="splitType === 1">修改支付渠道(<span style="color:#f40">分单只能修改子帐单</span>)</el-button>
          <el-button type="primary" @click="clickFunCase('tabOne')">打印</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 台桌消费弹出框2 -->
    <el-dialog v-model="tab2" title="会员充值" width="75%">
      <template #default>
        <div id="tabTwo">
          <div style="font-size:16px;padding:20px">
            <p>会员：{{tableData2[0].memberCardNumber}}&nbsp;时间：{{tableData2[0].detailTime}}     编号：{{tableData2[0].orderSerial}}</p>
          </div>
          <el-table :data="tableData2" style="width: 100%">
            <el-table-column prop="incomeType" label="名称"/>
            <el-table-column prop="integralRate" label="数量"/>
            <el-table-column prop="incomeMoney" label="充值金额" />
            <el-table-column prop="incomeRule" label="实际金额"/>
          </el-table>       
          <div style="margin-top:30px">
            <p>合计：{{tableData2[0].incomeMoney}}  
              <span v-if="tableData2[0].givingRule">&nbsp;&nbsp;充值规则:充{{tableData2[0].incomeMoney}}送{{tableData2[0].givingRule}}</span>
            </p>
            <p style="font-size:16px;white-space: pre-wrap;">其中：{{tableData2[0].incomeType}}：{{tableData2[0].incomeMoney}}</p>
            <p>结算人：{{tableData2[0].dutyName}}</p>
            <p>备注：{{tableData2[0].orderDesc}}</p>
          </div>
        </div>
        <!-- <div v-for="i in tableDatail" :key="i"> -->

      </template>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="clickFunCase('tabTwo')">打印</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 台桌消费弹出框3 -->
    <el-dialog v-model="tab3" title="前台支出" width="65%">
      <template #default>
        <div id="tabThree">
          <div style="font-size:19px;padding:20px">
            <p>时间：{{tableData3.dutyName}}     操作人：{{tableData3.dutyName}}</p>
          </div>
          <el-table :data="tableData3.rows" style="width: 100%">
            <el-table-column prop="itemName" label="支出项目"/>
            <el-table-column prop="itemMoney" label="支出金额"/>
            <el-table-column prop="incomeType" label="支出渠道" />
          </el-table>       
          <div>
            <p>总计：{{tableData3.rows[0].itemMoney}}</p>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="clickFunCase('tabThree')">打印</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 修改支付渠道 -->
    <el-dialog v-model="modPay" width="35%">
      <template #default>
        <div style="font-size:16px">
          <p><span>台号：{{tableTotal.tableName}}</span>&nbsp;&nbsp;<span>翻台次数：{{tableTotal.tableCounter}}</span></p>
          <p>编号：{{tableTotal.orderSerial}}</p>
          <p>应收金额：<span style="font-size:18px">{{(tableTotal.finalMoney-0).toFixed(2)}}</span></p>
          <div class="modPay">
            <div class="modPay-list" v-if="tableChange.vipType != 3">
              <div class="list-name">现金(散客)：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.incomeCashOther"
                  maxlength="10"

                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list" v-if="tableChange.vipType == 3">
              <div class="list-name">现金(会员)：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.incomeCashMember"
                  maxlength="10"

                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list">
              <div class="list-name">银行卡：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.bankMoney"
                  maxlength="10"

                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list">
              <div class="list-name">支付宝：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.alipayMoney"
                  maxlength="10"
      
                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list">
              <div class="list-name">微信：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.wechartMoney"
                  maxlength="10"
  
                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list">
              <div class="list-name">团购：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.groupMoney"
                  maxlength="10"
         
                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list">
              <div class="list-name">代金券：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.voucherDeduct"
                  maxlength="10"
         
                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list" v-if="tableChange.vipType == 3">
              <div class="list-name">储值卡：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.vipCard"
                  maxlength="10"
                  disabled
                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list">
              <div class="list-name">扫码支付：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.scan"
                  maxlength="10"
                  disabled
                  show-word-limit
                  type="text"
                >
                </el-input>
              </div>
            </div>
            <div class="modPay-list">
              <div class="list-name">备注：</div>
              <div class="list-input">
                <el-input
                  v-model="tableChange.incomeDesc"
                  maxlength="30"
                  placeholder="无备注"
                  show-word-limit
                  type="textarea"
                />
              </div>
            </div>
          </div>
          <div style="height:40px;">
            <el-button type="info" @click="modPay = false" style="float:right">取消</el-button>
            <el-button type="primary" @click="alterFunMoney()" style="float:right;margin-right:20px">确认</el-button>

          </div>
        </div>
      </template>
    </el-dialog>
    <Print rul='report/exportBillStatistics.json' :add='printAdd'/>
  </div>
</template>

<script>
import {ElNotification,ElMessage} from'element-plus'
import {ref,watch} from 'vue'
import {useRoute} from 'vue-router'
import {postData} from'@a'
import {cell} from '@u/cell.js'
export default {
  setup(){
    let route = useRoute()
    let modPayVip = ref(false)
    let modPay = ref(false)
    let printAdd = ref('')
    let options1 = ref([
      {value:'全部',label:'全部'},
    ])
    let options4 = ref([
      {value:'全部',label:'全部'}
    ])
    let tab2 = ref(false)
    let tab3 = ref(false)
    let tableData2 = ref([])
    let tableData3 = ref([])
    let value1 = ref('全部')
    let value4 = ref('全部')
    let splitType = ref(0)
    const dataOne = ref([
      {
        date:200
      }
    ])
    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      return style
    }
    
    const cellStyle = ({ row, column })=>{
      if(!row)console.log(1)
      let style  = {}
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      if(column.property === 'check' && column.label === '操作'){
        style.cursor= 'pointer'
        style.color = '#619BD8'
      }
      return style
    }
    const clickFun = async(row, column)=>{
      splitType.value = row.splitType
      if(column.label==="操作"){
        console.log(row)
        if(row.incomeType == 0){
          let param = {expendOrderId:row.expandOrderId,shopId:route.query.shopId,year:new Date(row.detailTime).getFullYear()}
          let res = await(postData('shop/payout/projectExpendDetail.json',param))
          res.data.rows[0].incomeType =  funMoney(res.data.rows[0].incomeType)
          tab3.value = true
          tableData3.value = res.data

        }else if(!row.orderId && row.incomeName !== "备用金订单"){
          let res = await(postData('order/recharge/detail.json',{detailId:row.detailId}))
          res.data.incomeType =  funMoney(res.data.incomeType)
          res.data.incomeRule = res.data.incomeMoney + res.data.givingRule
          tableData2.value[0] = res.data
          tab2.value = true
        }else if(row.incomeName!="备用金订单"){
          outerVisible.value = true
          let param = {
            orderId:row.orderId,
            shopId:route.query.shopId,
            year: new Date(row.detailTime).getFullYear(),
            vipType:row.orderType,
          }
          orderInfo(param)
        }

      }
    }
    let tableChange = ref({
      incomeCashOther:0,//散客现金
      incomeCashMember:0,//会员现金
      bankMoney:0,
      alipayMoney:0,
      wechartMoney:0,
      groupMoney:0,
      voucherDeduct:0,
      vipCard:0,
      scan:0,
      incomeDesc:'',
      vipType:0,
      orderId:'',
    })
    const alterFunMoney = ()=>{
      let money = 0
      for(let key in tableChange.value){
        if(key != 'incomeDesc' && key!='vipType' && key !='orderId')
        money += Number(tableChange.value[key])
      }
      if(money === tableTotal.value.finalMoney){
        changePayMoney()
      }else{
        ElNotification({
          title: '合计与应付金额不匹配',
          message: '请重新填写',
          type: 'error',
        })
      }
    }
    const changePayMoney = async()=>{
      tableChange.value.year = new Date(route.query.dateMin).getFullYear()
      try {
        await(postData('pay/changePayType.json',tableChange.value))
        ElMessage({
          message: '修改成功！',
          type: 'success',
        })
        modPay.value = false
      } catch (error) {
        console.log(error)
      }

    }
    const alterDatainfo = (num,type)=>{
      switch(type){
        case 4 :
          tableChange.value.incomeCashOther += Number(num)
          break;
        case 3 :
          tableChange.value.incomeCashMember += Number(num)
          break;
        case 8 :
          tableChange.value.bankMoney += Number(num)
          break;
        case 9 :
          tableChange.value.alipayMoney += Number(num)
          break;
        case 10 :
          tableChange.value.wechartMoney += Number(num)
          break;
        case 11 :
          tableChange.value.groupMoney += Number(num)
          break;
        case 2 :
          tableChange.value.vipCard += Number(num)
          break;
        case 22 :
          tableChange.value.voucherDeduct += Number(num)
          break;
        case 28 :
          tableChange.value.scan += Number(num)
          break;
        default  :
          break;
      }
    }
    // 修改支付渠道
    const alterTableData = ()=>{
      outerVisible.value = false;
      modPay.value = true
      tableChange.value = {
        incomeCashOther:0,//散客现金
        incomeCashMember:0,//会员现金
        bankMoney:0,
        alipayMoney:0,
        wechartMoney:0,
        groupMoney:0,
        voucherDeduct:0,
        vipCard:0,
        incomeDesc:'',
        scan:0,
        vipType:tableTotal.value.vipType,
        orderId:tableTotal.value.orderId
      }
      for(let i = 0;i<tableTotal.value.incomes.length;i++){
          alterDatainfo(tableTotal.value.incomes[i].incomeMoney,tableTotal.value.incomes[i].incomeType)
      }
    }
    let tableDatail = ref([])
    let tableTotal = ref({})
    const orderInfo = async(param)=>{
      let res = await(postData('pay/orderInfo.json',param))
      tableDatail.value = res.data.orders
      tableTotal.value = {totalDiscount:0,reduceMoney:0,orderMoney:0,finalMoney:0,incomeMoneyText:' ',vipType:0}
      // console.log(tableDatail.value,res.data.orders)
      if(tableDatail.value.length>1){
        for(let i = 0;i<tableDatail.value.length;i++){
          // console.log(tableTotal.value)
          // tableTotal.value.itemUnit = cell(tableTotal.value.itemUnit)
          for(let j = 0;j<tableDatail.value[i].rows.length;j++){
            console.log(tableDatail.value[i].rows[j])
            tableDatail.value[i].rows[j].itemUnit = cell(tableDatail.value[i].rows[j].itemUnit)
          }
          tableTotal.value.totalDiscount += Number(tableDatail.value[i].totalDiscount)
          tableTotal.value.reduceMoney += Number(tableDatail.value[i].reduceMoney)
          tableTotal.value.orderMoney += Number(tableDatail.value[i].orderMoney) //合计
          tableTotal.value.finalMoney += Number(tableDatail.value[i].finalMoney)  //应收
          tableTotal.value.payUserName = tableDatail.value[i].payUserName
          tableTotal.value.incomes = tableDatail.value[0].incomes
          tableTotal.value.tableName = tableDatail.value[0].tableName
          tableTotal.value.tableCounter = tableDatail.value[0].tableCounter
          tableTotal.value.orderSerial = tableDatail.value[0].orderSerial
          tableTotal.value.vipType = tableDatail.value[0].cardId && tableDatail.value[0].memberId?3:0
          tableTotal.value.orderId = param.orderId
        }
      }else{
        // console.log(JSON.stringify(tableDatail.value[0]))
        for(let i = 0;i<tableDatail.value[0].rows.length;i++){
          console.log(tableDatail.value[0].rows[i])
          tableDatail.value[0].rows[i].itemUnit = cell(tableDatail.value[0].rows[i].itemUnit)
        }
        tableTotal.value = tableDatail.value[0]
        tableTotal.value.vipType =res.data.orders[0].cardId && tableDatail.value[0].memberId?3:0
        tableTotal.value.orderId = param.orderId
      }
      tableTotal.value.incomeMoneyText = ' '
      for(let i = 0;i<tableTotal.value.incomes.length;i++){
        if(i!=0)tableTotal.value.incomeMoneyText = tableTotal.value.incomeMoneyText + '  +  '
        if(tableTotal.value.incomes[i].incomeType==0){
          tableTotal.value.incomeMoneyText = tableTotal.value.incomeMoneyText + '现金支出'+tableTotal.value.incomes[i].incomeMoney
        }else{
          // if(tableTotal.value.incomes[i].count < 2){
            tableTotal.value.incomeMoneyText = tableTotal.value.incomeMoneyText + funMoney(tableTotal.value.incomes[i].incomeType)+ " : " + tableTotal.value.incomes[i].incomeMoney
          // }else{
          //   tableTotal.value.incomeMoneyText = '混合结算'
          // }
        }
      }
    }
    // 支付方式
    const funMoney = (d)=>{
      let text = ''
      switch(d){
        case -1:
          text =  '组合结算';
          break;
        case 0:
          text =  '支出';
          break;
        case 1:
          text =  '会员现金充值';
          break;
        case 2:
          text =  '会员储值卡结算';
          break;
        case 3:
          text =  '会员现金结算';
          break;
        case 4:
          text =  '散客现金结算';
          break;
        case 5:
          text =  '赊账';
          break;
        case 6:
          text =  '现金还款';
          break;
        case 7:
          text =  '免单';
          break;
        case 8:
          text =  '网银消费';
          break;
        case 9:
          text =  '支付宝消费';
          break;
        case 10:
          text =  '微信消费';
          break;
        case 11:
          text =  '团购消费';
          break;
        case 12:
          text =  '网银充值';
          break;
        case 13:
          text =  '支付宝充值';
          break;
        case 14:
          text =  '微信充值';
          break;
        case 15:
          text =  '网银还款';
          break;
        case 16:
          text =  '支付宝还款';
          break;
        case 17:
          text =  '微信还款';
          break;
        case 18:
          text =  '还款免单';
          break;
        case 19:
          text =  '团购还款';
          break;
        case 20:
          text =  '团购充值';
          break;
        case 21:
          text =  '积分结算';
          break;
        case 22:
          text =  '代金卷结算'
          break;
        case 23:
          text =  '会员储值卡还款';
          break;
        case 24:
          text =  '网银支出';
          break;
        case 25:
          text =  '支付宝支出';
          break;
        case 26:
          text =  '微信支出';
          break;
        case 27:
          text =  '团购支出';
          break;
        case 28:
          text =  '扫码收入';
          break;
        case 29:
          text =  '扫码还款';
          break;
        case 30:
          text =  '扫码充值';
           break;   
        default:
          text =  "-";
          break;
      }
      return text
    }
    // 弹出框的状态
    let outerVisible= ref(false)
    const reqFun = ()=>{
      let param ={
        shopId:route.query.shopId,
        pageNo:1,
        pageSize:14, 
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        }
      }
      info(param)
    }
    watch(()=>route.query,()=>{
      if(route.name !== 'aloneShop') return
      reqFun()
      infoTable()
      infoUserList()
    })
    let pagingNum = ref(10) //分页数
    let pagNum = ref(1)       //当前页
    const pagingReq = (num)=>{  //分页请求
      pagNum.value = num;
      let param = {
        shopId:route.query.shopId,
        pageNo:pagNum.value,
        pageSize:14,
      }
      printAdd.value= ''
      if(value1.value != '全部'){
        param.tableId = value1.value
        printAdd.value += '&tableId=' + value1.value
      }
      if(value4.value != '全部'){
        param.payUser = value4.value
        printAdd.value += '&payUser=' + value4.value
      }
      if(route.query.dateType === '年'){
        param.from = route.query.dateMin+'-01-01'
        param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
        param.from = route.query.dateMin
        if(route.query.dateMax){
          param.to = route.query.dateMax
        }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
        }
      }
      info(param)
    }
    let revenuesAllMoney = ref(0)
    let expendAllMoney = ref(0)
    const info = async(param)=>{
      try {
        let res = await(postData("shop/bill_statistics.json",param))
        expendAllMoney.value = res.data.expendAllMoney
        revenuesAllMoney.value = res.data.revenuesAllMoney
        pagingNum.value = Math.ceil(res.data.total/14)*10
        if(printAdd.value.indexOf('pageSize=') === -1){
          printAdd.value += '&pageSize=' + res.data.total
        }else{
          printAdd.value = printAdd.value.split('&')
          // printAdd.value.forEach(e=>{

          //   if(e.indexOf('pageSize=') !== -1){
          //     e = 'pageSize=' + res.data.total
          //     console.log('aaa',e)
          //   }
          // })
          for(let i = 0;i<printAdd.value.length;i++){
            if(printAdd.value[i].indexOf('pageSize=') !== -1){
              printAdd.value[i] = 'pageSize=' + res.data.total
              console.log('aaa',printAdd.value[i])
              break;
            }
          }
          console.log(printAdd.value)
          printAdd.value = printAdd.value.join('&')
          console.log(printAdd.value)
        }
        dataOne.value = res.data.rows
        for(let i = 0;i<dataOne.value.length;i++){
          if(dataOne.value[i].count < 2){
            dataOne.value[i].incomeTypeText = funMoney(dataOne.value[i].incomeType)
          }else{
            dataOne.value[i].incomeTypeText = '混合结算'
          }
          
        }
        dataOne.value.forEach((e) => {
          e.check = '查看'
        });
        console.log(dataOne.value)
      } catch (error) {
        console.log(error)
      }
    }
    let param ={
      shopId:route.query.shopId,
      pageNo:1,
      pageSize:14,  
    }
    if(route.query.dateType === '年'){
      param.from = route.query.dateMin+'-01-01'
      param.to = (route.query.dateMin-0)+'-12-31'
      }else if(route.query.dateType === '班次'){
        param.dutyIds = route.query.dateMin
      }else{
      param.from = route.query.dateMin
      if(route.query.dateMax){
        param.to = route.query.dateMax
      }else{
          let date = new Date()
          param.to = (date.getFullYear()) + '-' + ((date.getMonth() + 1)>9?(date.getMonth() + 1):('0' + (date.getMonth() + 1))) + '-' + ((date.getDate())>9?(date.getDate()):('0'+(date.getDate())))
      }
    }
    const infoTable = async()=>{
      try {
        let res = await(postData("table/getTables.json",{shopId:route.query.shopId}))
          options1.value = [{value:'全部',label:'全部'}]
          for(let i = 0 ;i<res.data.length;i++){
            options1.value.push({value:res.data[i].tableId,label:res.data[i].tableName})
          }
      } catch (error) {
        console.log(error)
      }
    }
    const infoUserList = async()=>{
      try {
        let res = await(postData("shop/getUserList.json",{shopId:route.query.shopId}))
        options4.value = [{value:'全部',label:'全部'}]
        for(let i = 0;i<res.data.length;i++){
          options4.value.push({value:res.data[i].userId,label:res.data[i].userName})
        }
      } catch (error) {
        console.log(error)
      }
    }
    const clickFunCase = (id)=>{
      window.printJS(id, 'html')
      // window.printJS('printHtml', document.querySelector(id))
    }
    infoTable()
    info(param)
    infoUserList()
    return{
      reqFun,
      pagingNum,
      pagNum,
      pagingReq,
      options1,
      options4,
      value1,
      value4,
      dataOne,
      headerStyle,
      cellStyle,
      clickFun,
      outerVisible,
      modPay,
      modPayVip,
      tableDatail,
      tableTotal,
      tableChange,
      alterFunMoney,
      alterTableData,
      revenuesAllMoney,
      expendAllMoney,
      tab2,
      tab3,
      tableData2,
      tableData3,
      clickFunCase,
      printAdd,
      splitType,

    }
  }
}
</script>

<style scoped>
main{
  margin: 25px;
}
.header{
  display: flex;
  /* justify-content: flex-start; */
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.header>div{
  padding-right: 20px;
  width: 300px;
}
.vip-type-list{
  display: flex;
  /* justify-content: center; */
  font-size: 16px;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 20px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
    font-weight: 600;
}
.modPay{
  display: flex;
  flex-direction: column;
}
.modPay-list{
  display: flex;
  align-items: center;
  padding:10px;
}
.modPay-list>div:nth-child(1){
  width: 100px;
}
.modPay-list>div:nth-child(2){
  flex:1;
}
</style>